angular.module("financier").constant("flags", [
  {
    color: "#ff0000",
    label: "RED",
  },
  {
    color: "#faa710",
    label: "ORANGE",
  },
  {
    color: "#e5e500",
    label: "YELLOW",
  },
  {
    color: "#76b852",
    label: "GREEN",
  },
  {
    color: "#5276b8",
    label: "BLUE",
  },
  {
    color: "#b852a9",
    label: "PURPLE",
  },
]);
