angular.module("financier").constant("defaultCategories", [
  {
    name: "MONTHLY_BILLS",
    categories: [
      {
        name: "RENT_MORTGAGE",
      },
      {
        name: "PHONE",
      },
      {
        name: "INTERNET",
      },
      {
        name: "CABLE_TV",
      },
      {
        name: "ELECTRICITY",
      },
      {
        name: "WATER",
      },
    ],
  },
  {
    name: "EVERYDAY_EXPENSES",
    categories: [
      {
        name: "SPENDING_MONEY",
      },
      {
        name: "GROCERIES",
      },
      {
        name: "FUEL",
      },
      {
        name: "RESTAURANTS",
      },
      {
        name: "MEDICAL_DENTAL",
      },
      {
        name: "CLOTHING",
      },
      {
        name: "HOUSEHOLD_GOODS",
      },
    ],
  },
  {
    name: "RAINY_DAY_FUNDS",
    categories: [
      {
        name: "EMERGENCY_FUND",
      },
      {
        name: "CAR_MAINTENANCE",
      },
      {
        name: "CAR_INSURANCE",
      },
      {
        name: "BIRTHDAYS",
      },
      {
        name: "CHRISTMAS",
      },
      {
        name: "RENTERS_INSURANCE",
      },
      {
        name: "RETIREMENT",
      },
    ],
  },
  {
    name: "SAVINGS_GOALS",
    categories: [
      {
        name: "CAR_REPLACEMENT",
      },
      {
        name: "VACATION",
      },
    ],
  },
  {
    name: "DEBT",
    categories: [
      {
        name: "CAR_PAYMENT",
      },
      {
        name: "STUDENT_LOAN_PAYMENT",
      },
    ],
  },
  {
    name: "GIVING",
    categories: [
      {
        name: "TITHING",
      },
      {
        name: "CHARITABLE",
      },
    ],
  },
]);
